import React, { Component } from "react";
import HtmlCss from "../../icons/HtmlCss";
import RoR from "../../icons/RoR"
import Node from "../../icons/Node";
import PhpSql from "../../icons/PhpSql";
import ReactRedux from "../../icons/ReactRedux";
import Test from "../../icons/Test";

const ServiceList = [
  {
    icon: <HtmlCss />,
    title: "HTML & CSS",
    description:
      "I have been using them for years to translate my designs in to the final UX / UI of my sites.",
  },
  {
    icon: <ReactRedux />,
    title: "ReactJS & Redux",
    description:
      "Powerful JS library that helps me to create highly customized apps with reusable components and dynamic states",
  },
  {
    icon: <Node />,
    title: "NodeJs ",
    description:
      "The backend JavaScript environment that I am using more often to execute code outside of the browser.",
  },
  {
    icon: <PhpSql />,
    title: "PHP & MySQL",
    description:
      "I have experience creating the API and database where to manage, process, and store data it receives from different devices",
  },
  {
    icon: <Test />,
    title: "Jest / RSpec",
    description:
      "My favorite testing libraries for React / Rails applications.",
  },
  {
    icon: <RoR />,
    title: "Ruby on Rails",
    description:
      "Powerful language to create backends in a fast and reliable way.",
  },
];

class ServiceThree extends Component {
  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <button className="invisible-button">
                <div className="service service__style--2">
                  <div className="icon">{val.icon}</div>
                  <div className="content">
                    <h3 className="title">{val.title}</h3>
                    <p>{val.description}</p>
                  </div>
                </div>
              </button>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
