import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="169.979"
      height="48"
      preserveAspectRatio="xMinYMin meet"
      version="1.1"
      viewBox="0 -183.5 169.979 48"
    >
      <g fill="#83cd29" transform="matrix(.33255 0 0 .33255 -.285 -183.627)">
        <path d="M471.05 51.611c-1.244 0-2.454.257-3.525.863l-33.888 19.57c-2.193 1.264-3.526 3.65-3.526 6.189v39.069c0 2.537 1.333 4.92 3.526 6.187l8.85 5.109c4.3 2.119 5.885 2.086 7.842 2.086 6.366 0 10.001-3.863 10.001-10.576V81.542c0-.545-.472-.935-1.007-.935h-4.245c-.544 0-1.007.39-1.007.935v38.566c0 2.975-3.1 5.968-8.13 3.453l-9.21-5.396c-.326-.177-.576-.49-.576-.863v-39.07c0-.37.247-.747.576-.935L470.547 57.8a.998.998 0 011.007 0l33.817 19.498c.322.194.576.553.576.936v39.069c0 .373-.188.755-.504.935l-33.889 19.498c-.29.173-.69.173-1.007 0l-8.706-5.18a.905.905 0 00-.863 0c-2.403 1.362-2.855 1.52-5.109 2.302-.555.194-1.398.495.288 1.44l11.368 6.69a6.995 6.995 0 003.526.936 6.949 6.949 0 003.525-.935l33.889-19.499c2.193-1.275 3.525-3.65 3.525-6.187v-39.07c0-2.538-1.332-4.92-3.525-6.187l-33.889-19.57c-1.062-.607-2.28-.864-3.525-.864z"></path>
        <path d="M480.116 79.528c-9.65 0-15.397 4.107-15.397 10.937 0 7.408 5.704 9.444 14.966 10.36 11.08 1.085 11.943 2.712 11.943 4.893 0 3.783-3.016 5.396-10.144 5.396-8.957 0-10.925-2.236-11.584-6.691-.078-.478-.447-.864-.936-.864h-4.389c-.54 0-1.007.466-1.007 1.008 0 5.703 3.102 12.447 17.916 12.447 10.723 0 16.908-4.209 16.908-11.584 0-7.31-4.996-9.273-15.398-10.648-10.51-1.391-11.512-2.072-11.512-4.533 0-2.032.85-4.75 8.634-4.75 6.954 0 9.524 1.5 10.577 6.189.092.44.48.791.935.791h4.39c.27 0 .532-.166.719-.36.184-.207.314-.44.288-.719-.68-8.074-6.064-11.872-16.909-11.872z"></path>
      </g>
      <path
        fill="#404137"
        strokeWidth="0.333"
        d="M90.11-183.5a.725.725 0 00-.359.096.725.725 0 00-.359.622v18.304c0 .18-.083.34-.239.43a.5.5 0 01-.502 0l-2.991-1.722a1.433 1.433 0 00-1.436 0l-11.94 6.891a1.47 1.47 0 00-.717 1.268v13.782c0 .515.271.987.717 1.245l11.94 6.89a1.43 1.43 0 001.436 0l11.94-6.89a1.433 1.433 0 00.717-1.245v-34.359a1.459 1.459 0 00-.741-1.268l-7.107-3.972a.698.698 0 00-.359-.072zm-76.807 17.204a1.349 1.349 0 00-.645.167l-11.94 6.891A1.43 1.43 0 000-157.994l.024 18.497c0 .257.133.496.359.622a.697.697 0 00.718 0l7.082-4.068c.449-.267.742-.73.742-1.244v-8.638c0-.516.27-.988.718-1.244l3.014-1.747a1.414 1.414 0 01.718-.192c.246 0 .5.062.718.192l3.015 1.747a1.429 1.429 0 01.718 1.244v8.637c0 .514.296.981.741 1.245l7.083 4.068a.697.697 0 00.718 0 .72.72 0 00.359-.623l.023-18.496a1.423 1.423 0 00-.717-1.244l-11.94-6.89a1.34 1.34 0 00-.646-.168zm107.29.167c-.249 0-.493.063-.717.192l-11.94 6.89a1.432 1.432 0 00-.718 1.245v13.782c0 .518.292.988.742 1.244l11.844 6.748c.437.25.971.268 1.412.023l7.178-3.995a.725.725 0 00.383-.622.707.707 0 00-.36-.623l-12.01-6.89a.773.773 0 01-.384-.647v-4.306c0-.258.16-.494.383-.623l3.733-2.153a.717.717 0 01.718 0l3.732 2.153a.716.716 0 01.36.622v3.398a.715.715 0 00.358.622c.224.13.495.13.718 0l7.154-4.163a1.438 1.438 0 00.718-1.244v-3.326c0-.514-.272-.986-.718-1.244l-11.868-6.891a1.423 1.423 0 00-.718-.192zm-35.7 10.289a.4.4 0 01.192.048l4.092 2.368a.371.371 0 01.192.311v4.738a.37.37 0 01-.192.311l-4.092 2.369a.362.362 0 01-.359 0l-4.091-2.369a.37.37 0 01-.192-.311v-4.738c0-.128.081-.246.192-.31l4.091-2.37a.315.315 0 01.168-.047z"
      ></path>
      <path
        fill="#83cd29"
        strokeWidth="0.333"
        d="M49.17-166.2c-.248 0-.494.062-.717.19l-11.94 6.868a1.47 1.47 0 00-.718 1.268v13.782c0 .516.272.986.718 1.245l11.94 6.89a1.429 1.429 0 001.435 0l11.94-6.89a1.433 1.433 0 00.718-1.245v-13.782c0-.515-.272-1.01-.718-1.268l-11.94-6.867a1.429 1.429 0 00-.718-.192zm71.4 12.298c-.048 0-.101 0-.144.024l-2.297 1.34a.28.28 0 00-.144.24v2.631c0 .1.058.19.144.24l2.297 1.316a.25.25 0 00.263 0l2.297-1.317a.281.281 0 00.144-.239v-2.632a.281.281 0 00-.144-.24l-2.297-1.34c-.042-.024-.072-.023-.12-.023z"
      ></path>
    </svg>
  );
}

export default Icon;
