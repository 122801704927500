// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ReactGA from 'react-ga';
import * as serviceWorker from "./serviceWorker";

// Create Import File
import "./index.scss";

import DarkPortfolioLanding from "./dark/PortfolioLanding";
import DelPrado from "./elements/projects/DelPrado";
import Nurumia from "./elements/projects/Nurumia";
import Grisu from "./elements/projects/Grisu";
import UneFelt from "./elements/projects/UneFelt";
import UneEcole from "./elements/projects/UneEcole";

import error404 from "./elements/error404";

class Root extends Component {
  setGA = () => {
    ReactGA.initialize('G-8RTYWV651F');
    ReactGA.pageview('Init page view');
  };
  componentDidMount() {
    this.setGA();
  }
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={DarkPortfolioLanding}
          />

          {/* Element Layout */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/portfolio-delprado`}
            component={DelPrado}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/portfolio-nurumia`}
            component={Nurumia}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/portfolio-grisu`}
            component={Grisu}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/portfolio-unefelt`}
            component={UneFelt}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/portfolio-uneecole`}
            component={UneEcole}
          />
          <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
          <Route component={error404} />
        </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
