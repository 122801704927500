import React, { Component } from "react";
import Url from "../../icons/Url";

const PortfolioListContent = [
  {
    image: "image-5",
    category: "Back-end Development",
    title: "Une Ecole ",
    href: `/portfolio-uneecole`,
    github: "https://github.com/Metsanpeitto/ruby-on-rails_e-school",
    web: "https://still-chamber-13739.herokuapp.com/",
    technologies: "Ruby on Rails, Postgres, Scss, Devise, Trix, Stripe",
    description:
      "E-learning platform where the user can create or join courses. It has all the features needed to create, promote, and share didactic material using a rich text editor. The social side of the project is enhanced by providing ways of feedback and communication between teacher and students. The application uses Devise to manage users and Stripe as payment method.",
  },
  {
    image: "image-4",
    category: "eCommerce Development",
    title: "Une Felt ",
    href: `/portfolio-unefelt`,
    github: "https://github.com/Metsanpeitto/react-redux_une-felt_e-commerce",
    web: "https://une.waldenberginc.com/",
    technologies:
      "ReactJS, Redux, Html5-Scss, Wordpress, Woocommerce, Figma, Design Study, Stripe, API-rest, ExpressJS",
    description:
      "Shop, Blog and Gallery in one site. It helps the artist to better manage her content and have a full control over her business.This is a headless Wordpress and WooCommerce backend, with a front-end developed in ReactJS / Redux and Sass created with state of the art Css animations and effects",
  },
  {
    image: "image-1",
    category: "eCommerce Development",
    title: "Del Prado ",
    href: `/portfolio-delprado`,
    github: "https://github.com/Metsanpeitto/react-redux_del-prado_e-commerce",
    web: "https://waldenberginc.com/",
    technologies:
      "ReactJS, Redux, Html5-Scss, Wordpress, Woocommerce, Stripe, API-rest, ExpressJS",
    description:
      "Website of a grocery store. Connected to Woocommerce offers all the possibilities that this one has, and serves them to the client in a highly customizable aspect, rather than the monolitic and constrained options that other options provides.This is a headless Wordpress and WooCommerce backend. With a front-end developed in ReactJS / Redux and Sass created with state of the art Css animations and effects",
  },
  {
    image: "image-2",
    category: "Full-stack Development",
    title: "Nurumia App",
    href: "/portfolio-nurumia",
    github: "https://github.com/Metsanpeitto/react-nurumia",
    web: "https://nurumia.waldenberginc.com/",
    technologies:
      "ReactJS, Html5-Scss, PHP, C++, IoT, ESP8266, API-rest, PCB-design, AVR-microcontroller",
    description:
      "Website and app to control and monitor an automated aquaponic greenhouse system. Chat, calendar, control dashboard connected through an API to a device also designed and built by myself.Build with ReactJs, Css, html and connected through a PHP API to a ESP8266 and a Atmega328 device. The device provides a flow of data and accept a serie of commands that can be programmed or directly executed",
  },
];

class PortfolioList extends Component {
  render() {
    const { column, styevariation } = this.props;
    const list = PortfolioListContent.slice(0, this.props.item);
    return (
      <React.Fragment>
        {list.map((value, index) => (
          <div className={`portfolio ${styevariation}`} key={index}>
            <div className="thumbnail-inner">
              <div className={`thumbnail ${value.image}`}></div>
              <div className={`bg-blr-image ${value.image}`}></div>
            </div>
            <div className="content">
              <div className="inner">
                <p>{value.category}</p>
                <h4>{value.title}</h4>
              </div>
            </div>
            <div className="overlay">
              <h4>{value.technologies}</h4>
              <p>{value.description}</p>
              <div className="portfolio-button">
                <a className="rn-btn" href={value.href}>
                  View Details
                </a>
                <a className="icon" href={value.web}>
                  <Url />
                </a>
                <a className="icon" href={value.github}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 36 36"
                  >
                    <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default PortfolioList;
