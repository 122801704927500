import React, { Component } from "react";

class UneFelt extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      src: null,
      visibility: "hidden",
      posY: null,
    };
    this.windowOffset = 0;
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps() {
    console.log(this.props);
    const isOpen = this.props.open;
    //const visibility = isOpen ? "visible" : "hidden";
    if (isOpen && this.props.el && this.props.modal) {
      this.setState(
        () => {
          return {
            // visibility: visibility,
          };
        },
        () => {
          this.openModal(this.props.el, this.props.modal);
        }
      );
    }
  }

  closeModal = () => {
    console.log("close");
    if (this.state.posY) {
      window.scrollTo(0, this.state.posY);
    }
    this.setState(
      () => {
        return {
          src: null,
          isOpen: null,
          posY: null,
          visibility: "hidden",
        };
      },
      () => {
        this.props.close();
      }
    );
  };

  openModal = (el, modal) => {
    var el = el;
    var modal = modal;
    console.log(el);
    console.log(modal);
    console.log(this.state);

    if (el && modal) {
      var img = null;
      for (var i = 0; i < el.length; i++) {
        console.log(el[i].getAttribute("src"));
        img = el[i].getAttribute("src");
      }
      console.log(window.scrollY);

      this.setState(
        () => {
          return {
            src: img,
            isOpen: true,
            posY: window.scrollY,
            visibility: "visible",
          };
        },
        () => {}
      );
    }
  };

  render() {
    const visibility = this.state.visibility;
    return (
      <div
        className="t--portfolio-details-modal"
        style={{ visibility: visibility }}
      >
        <div className="selectable-area" onClick={this.closeModal}></div>
        <img
          className="modal-img"
          src={this.state.src}
          alt="Portfolio Images"
        />
      </div>
    );
  }
}
export default UneFelt;
