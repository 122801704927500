import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";

class TabsTwo extends Component {
  render() {
    let tab1 = "Main skills",
      tab2 = "Experience",
      tab3 = "Resume";
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab3}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <h6 className="invisible-button">
                            Website and App design <span> - Frontend</span>
                          </h6>
                          <p> ReactJS - Redux - HTML - Sass - Figma</p>
                        </li>
                        <li>
                          <h6 className="invisible-button">
                            eCommerce <span> - Fullstack</span>
                          </h6>
                          <p>Online Stores customized Frontend and Backend.</p>
                        </li>
                        <li>
                          <h6 className="invisible-button">
                            Custom APIs and Databases<span> - Backend</span>
                          </h6>
                          <p>
                            {" "}
                            Ruby on Rails, Node.js, MongoDB, Php, Mysql,
                            Postgres, Firebase, AWS, Docker
                          </p>
                        </li>
                        <li>
                          <h6 className="invisible-button">
                            Test Driven Development <span> - Fullstack</span>
                          </h6>
                          <p> RSpec - Jest - Vitest </p>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        {" "}
                        <li>
                          <a href="https://www.joinwaterlily.com/">
                            {" "}
                            <h6 className="invisible-button">
                              {" "}
                              Waterlily (San Francisco based startup){" "}
                              <span> - FullStack Web Developer</span>
                            </h6>{" "}
                          </a>

                          <p>2023 - 2023</p>
                        </li>{" "}
                        <li>
                          <a href="https://www.ericsson.com/en/portfolio/cloud-software-and-services/cognitive-network--solutions/cognitive-software">
                            {" "}
                            <h6 className="invisible-button">
                              {" "}
                              Experis.es (Ericsson Cognitive Software){" "}
                              <span> - Frontend Web Developer</span>
                            </h6>{" "}
                          </a>

                          <p>2022 - 2023</p>
                        </li>
                        <li>
                          <a href="https://airgateway.com/">
                            {" "}
                            <h6 className="invisible-button">
                              {" "}
                              Airgateway <span> - Web Developer</span>
                            </h6>{" "}
                          </a>

                          <p>2020 - 2021</p>
                        </li>
                        <li>
                          <a href="https://une-felt.com/">
                            {" "}
                            <h6 className="invisible-button">
                              {" "}
                              Une Felt <span> - FullStack Web Developer </span>
                            </h6>{" "}
                          </a>

                          <p>2020 - 2021</p>
                        </li>
                        <li>
                          <a href="https://waldenberginc.com/">
                            {" "}
                            <h6 className="invisible-button">
                              {" "}
                              Del Prado <span> - FullStack Web Developer </span>
                            </h6>{" "}
                          </a>

                          <p>2020 - 2021</p>
                        </li>
                        <li>
                          <a href="https://nurumia.waldenberginc.com/">
                            {" "}
                            <h6 className="invisible-button">
                              {" "}
                              Nurumia <span> - FullStack Web Developer </span>
                            </h6>{" "}
                          </a>

                          <p>2019 - 2020</p>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <Link
                            to="/files/Resume.pdf"
                            target="_blank"
                            download
                            color="blue"
                          >
                            My Resume
                            <span> - Download it from here </span>
                          </Link>
                        </li>
                        <li>
                          <p className="invisible-text">
                            {" "}
                            Filling space<span>Filling space </span>
                          </p>
                        </li>
                        <li>
                          <p className="invisible-text">
                            Filling space <span> </span>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
