import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import Modal from "../../elements/common/Modal";
import Url from "../../icons/Url";

class UneEcole extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            el: null,
            modal: null,
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        console.log(e);
        if (!this.state.isOpen) {
            var el = e.currentTarget.getElementsByTagName("img");
            var modal = document.getElementsByClassName("t--portfolio-details-modal");
            this.setState(
                () => {
                    return { isOpen: true, el: el, modal: modal };
                },
                () => { }
            );
        } else {
            this.setState(() => {
                return { isOpen: null, el: null, modal: null };
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    open={this.state.isOpen}
                    el={this.state.el}
                    modal={this.state.modal}
                    close={this.handleClick}
                />
                <PageHelmet pageTitle="Portfolio Details" />

                <Header homeLink="/" logo="symbol-dark" color="color-black" />

                {/* Start Breadcrump Area */}
                <div
                    className="rn-page-title-area pt--120 pb--190 bg_image bg_uneecole"
                    data-black-overlay="7"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Une ecole</h2>
                                    <p>Online learning platform</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>E-school</h2>
                                        <p className="subtitle">PROJECT DESCRIPTION.</p>
                                        <p>
                                            This app is an e-school platform for the artist and creator Une felt.
                                            The main goal of this app is to provide a space where knowledgable people
                                            can share and teach others their skills.
                                        </p>

                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Technologies</span>
                                                <h4>
                                                    Ruby on rails, Postgres, Rswag, RSpec, API-rest, Stripe.
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="links">
                                            {" "}
                                            <Url />{" "}
                                            <a href="https://still-chamber-13739.herokuapp.com/">
                                                <p className="link">
                                                    https://still-chamber-13739.herokuapp.com/
                                                </p>
                                            </a>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                                            </svg>{" "}
                                            <a href="https://github.com/Metsanpeitto/ruby-on-rails_e-school">
                                                <p className="link">
                                                    https://github.com/Metsanpeitto/ruby-on-rails_e-school
                                                </p>
                                            </a>
                                        </div>

                                        <div className="portfolio-thumb-inner">
                                            <div className="thumb mb-20pc mt-10pc">
                                                <a href="#" onClick={this.handleClick}>
                                                    <img
                                                        src="/assets/images/portfolio/uneEcole/login.png"
                                                        alt="Portfolio Images"
                                                    />
                                                </a>
                                            </div>
                                        </div>

                                        <p className="subtitle">DEVELOPMENT.</p>
                                        <p>
                                            The app uses a Postgres database. It manages all the information related with
                                            the proccess of creating courses, with a rich text editor. Users can have the role
                                            of teacher or student, depending on the package they purchase in the online shop.

                                        </p>

                                        <div className="thumb mb-20pc mt-10pc">
                                            <a href="#" onClick={this.handleClick}>
                                                <img
                                                    src="/assets/images/portfolio/uneEcole/course-overview.png"
                                                    alt="Portfolio Images"
                                                />
                                            </a>
                                        </div>

                                        <p className="subtitle">SELECTION OF TASKS.</p>
                                        <ul>
                                            <li>
                                                <p>
                                                    Acted as the software architect and
                                                    developer planning and executing every part of the
                                                    whole platform.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    Created the data models and relations between resources to fit the
                                                    needs of this kind of application.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    Added Stripe payments so teachers can pay for the service of hosting their
                                                    courses, and students pay to the teacher by the courses they purchase.
                                                </p>
                                            </li>
                                        </ul>

                                        <div className="thumb mb-20pc mt-10pc">
                                            <a href="#" onClick={this.handleClick}>
                                                <img
                                                    src="/assets/images/portfolio/uneEcole/teacher_dashboard.png"
                                                    alt="Portfolio Images"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">
                                        Related Work
                                    </span>
                                    <h2>More Projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb ">
                                        <a href="/portfolio-delprado">
                                            <img
                                                src="/assets/images/portfolio/uneSmall.png"
                                                alt="Portfolio Images"
                                            />
                                        </a>
                                    </div>

                                    <div className="inner">
                                        <h4>
                                            <a href="/portfolio-unefelt">Une-Felt</a>
                                        </h4>
                                        <span className="category">eCommerce</span>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/portfolio-nurumia">
                                            <img
                                                src="/assets/images/portfolio/nurumiaSmall.jpg"
                                                alt="Portfolio-images"
                                            />
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4>
                                            <a href="/portfolio-nurumia">Nurumia</a>
                                        </h4>
                                        <span className="category">Greenhouse Control</span>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />
            </React.Fragment>
        );
    }
}
export default UneEcole;
