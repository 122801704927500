import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512pt"
      height="512pt"
      viewBox="0 -27 512 511"
    >
      <path d="M310.004 168.5c-48.524 0-88 39.477-88 88s39.476 88 88 88c48.523 0 88-39.477 88-88s-39.477-88-88-88zm0 156c-37.496 0-68-30.504-68-68s30.504-68 68-68 68 30.504 68 68-30.504 68-68 68zm0 0"></path>
      <path d="M310.004 211.504c-5.524 0-10 4.476-10 10 0 5.523 4.476 10 10 10 13.781 0 24.996 11.215 24.996 24.996 0 5.523 4.477 10 10 10s10-4.477 10-10c0-24.813-20.184-44.996-44.996-44.996zm0 0M61.645 75.902c-2.54-4.902-8.57-6.82-13.477-4.285-4.906 2.54-6.824 8.574-4.285 13.48l26.918 52c1.742 3.368 5.265 5.426 9.031 5.403a10.004 10.004 0 008.863-5.672l16.297-33.93 17.7 34.2a10.009 10.009 0 008.882 5.402h.149a10.01 10.01 0 008.867-5.672l24.972-52c2.391-4.976.293-10.953-4.683-13.344-4.98-2.39-10.953-.293-13.344 4.688l-16.297 33.93-17.703-34.2c-1.742-3.367-5.258-5.433-9.027-5.402a10.005 10.005 0 00-8.867 5.672l-16.293 33.93zm0 0M125.988 20.5c2.633 0 5.211-1.07 7.07-2.93 1.864-1.86 2.93-4.441 2.93-7.07s-1.066-5.21-2.93-7.07a10.073 10.073 0 00-7.07-2.93 10.07 10.07 0 00-7.066 2.93c-1.863 1.86-2.934 4.441-2.934 7.07s1.07 5.21 2.934 7.07a10.07 10.07 0 007.066 2.93zm0 0"></path>
      <path d="M512 172.5v-132c0-22.055-17.945-40-40-40H167.988c-5.52 0-10 4.477-10 10s4.48 10 10 10H472c11.027 0 20 8.973 20 20v132c0 11.027-8.973 20-20 20h-48c-.29 0-.57.02-.852.043-15.433-27.195-40.382-48.305-70.293-58.777l14.825-30.864 17.703 34.196a9.991 9.991 0 008.879 5.402h.152a10.004 10.004 0 008.863-5.672l24.973-52c2.39-4.976.293-10.95-4.684-13.34-4.976-2.394-10.953-.297-13.343 4.684l-16.293 33.93-17.703-34.2c-1.743-3.367-5.254-5.433-9.032-5.398a9.988 9.988 0 00-8.863 5.668l-16.297 33.93-17.7-34.2c-2.538-4.902-8.573-6.82-13.48-4.28-4.906 2.538-6.82 8.57-4.285 13.476l22.133 42.754a130.581 130.581 0 00-18.695-1.352 129.72 129.72 0 00-35.477 4.922l22.38-46.594c2.39-4.976.292-10.95-4.688-13.34-4.977-2.394-10.953-.297-13.34 4.684l-16.297 33.93-17.703-34.2c-1.742-3.367-5.238-5.433-9.031-5.398a9.993 9.993 0 00-8.864 5.668l-16.293 33.93-17.703-34.2c-2.539-4.902-8.57-6.82-13.476-4.28a9.997 9.997 0 00-4.286 13.476l26.918 52c1.743 3.367 5.25 5.425 9.032 5.402a10.004 10.004 0 008.863-5.672l16.297-33.926 17.7 34.196c.296.574.66 1.093 1.054 1.59-24.524 11.476-44.899 30.382-58.207 53.812H40c-11.027 0-20-8.973-20-20v-132c0-11.027 8.973-20 20-20h40.992c5.524 0 10-4.477 10-10s-4.476-10-10-10H40C17.945.5 0 18.445 0 40.5v132c0 22.059 17.945 40 40 40h147.676c-4.961 13.75-7.672 28.563-7.672 44 0 71.684 58.316 130 130 130 23.555 0 45.668-6.297 64.742-17.297l79.367 79.367c6.61 6.61 15.293 9.914 23.977 9.914s17.367-3.304 23.976-9.914c13.223-13.222 13.223-34.734 0-47.957l-31.183-31.183c-3.906-3.903-10.238-3.907-14.14 0a9.996 9.996 0 000 14.14l31.183 31.188c5.422 5.422 5.422 14.246 0 19.672-5.422 5.422-14.246 5.422-19.668 0l-76.738-76.742c29.546-23.848 48.484-60.344 48.484-101.188 0-15.437-2.711-30.25-7.672-44H472c22.059 0 40-17.945 40-40zm-201.996 194c-60.652 0-110-49.348-110-110s49.348-110 110-110 110 49.348 110 110-49.344 110-110 110zm0 0"></path>
      <path d="M433.02 345.71a10.073 10.073 0 00-2.93 7.071c0 2.63 1.07 5.207 2.93 7.07 1.87 1.86 4.44 2.93 7.07 2.93s5.21-1.07 7.07-2.93a10.037 10.037 0 002.93-7.07c0-2.633-1.059-5.21-2.93-7.07a10.059 10.059 0 00-7.07-2.93 10.08 10.08 0 00-7.07 2.93zm0 0"></path>
    </svg>
  );
}

export default Icon;
